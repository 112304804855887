import React, { FC } from 'react';
import { DefaultTemplate } from 'templates';
import { Cell, Grid, SectionContainer } from 'components/Grid';
import { Heading } from 'components/Layout';
import { ContactButton } from 'components/Buttons';
import { Link } from 'components/helpers';

import styles from 'scss/landing-page.module.scss';
import gridStyles from 'scss/grid.module.scss';

const LandingPage: FC = () => {
  return (
    <DefaultTemplate
      pageTitle='Soft Skills and Leadership - Software Engineering Interview Preparation'
      metaDescription='Develop your soft skills and leadership abilities with our training program. One 1-hour session with customized exercises to improve communication, conflict resolution, time management, and leadership development. Stand out as a capable and effective leader in your next interview.'
    >
      <div className={styles.pageMain}>
        <SectionContainer name='soft-skills-and-leadership-interview'>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12 }}>
              <p>
                <Link to='/interview-preparation'>← Back to overview</Link>
              </p>
              <Heading tagLevel={1} classes={gridStyles.marginTop0}>
                <strong>Soft Skills and Leadership Inteview</strong>
              </Heading>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Overview
              </Heading>
              <p>
                In this one-hour crash course, we will focus on developing your
                soft skills and leadership abilities. We will work together to
                identify areas that require improvement and create a customized
                plan to help you enhance your skills.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session - 1 hour
              </Heading>
              <p>
                We will cover topics that are likely to come up in soft skill
                focused interviews such as effective communication, conflict
                resolution, time management, and leadership development. You
                will gain valuable insights, tips, and techniques to help you
                become a more effective leader and team player.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Results to expect
              </Heading>
              <p>
                By the end of the session, you will have a clear understanding
                of your strengths and areas for growth, as well as a roadmap to
                help you achieve your leadership goals. This training will help
                you become a more confident and capable leader, equipped with
                the skills necessary to succeed in any workplace.
              </p>
              <div className={gridStyles.textCenter}>
                <ContactButton>Email to schedule</ContactButton>
              </div>
            </Cell>
          </Grid>
        </SectionContainer>
      </div>
    </DefaultTemplate>
  );
};

export default LandingPage;
